
import { Options, Vue } from "vue-class-component";
import { downloadFile } from "@/app/infrastructures/misc/Utils";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ConfigBucketStorage } from "@/feature-flags/flags-config-bucket-storage";

@Options({
  components: {
    DetailLayout
  }
})
export default class TabTemplate extends Vue {
  //   Search
  searchValue = "";
  onSearch(value: string) {
    this.searchValue = value;
  }
  onClearSearch() {
    this.searchValue = "";
  }

  get filteredStepFillTemplate() {
    return this.tempList.filter(item =>
      this.searchValue
        ? item.title.toLowerCase().includes(this.searchValue.toLowerCase())
        : true
    );
  }

  get accountType() {
    return AccountController.accountData.account_type;
  }

  stepFillTemplate = [
    {
      title: "List of Permission",
      description: "Template List of Permission",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/template-role-20210628.csv`,
      extension: "csv"
    },
    {
      title: "Role",
      description: "Template role",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/role1608520351.csv`,
      extension: "csv"
    },
    {
      title: "Account",
      description: "Template account",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/account1608519849.csv`,
      extension: "csv"
    },
    {
      title: "Manajemen Pelanggan",
      description: "Template manajemen pelanggan",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/manajemen-pelanggan-122024.csv`,
      extension: "csv"
    }
  ];

  internalTemplate = [
    {
      title: "Syarat dokumen internasional",
      description: "Template syarat dokumen internasional.  ",
      url: `https://dev-genesis.s3.ap-southeast-1.amazonaws.com/add_document_international_122024.csv`,
      extension: "csv"
    },
    {
      title: "Route Mapping",
      description: "Template route mapping",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/route1608520197.csv`,
      extension: "csv"
    },
    {
      title: "Commodity",
      description: "Template commodity",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/bulk/template/commodity_12-2024.csv`,
      extension: "csv"
    },
    {
      title: "Create Commodity Surcharge",
      description: "Template for create commodity surcharge",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/template_create_commodity_surcharge_2023-11-13.csv`,
      extension: "csv"
    },
    {
      title: "Update Commodity Surcharge",
      description: "Template for update commodity surcharge",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/template-commodity-surcharge-2023-10-31.csv`,
      extension: "csv"
    },
    {
      title: "Leg",
      description: "Template leg",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/leg1608520110.csv`,
      extension: "csv"
    },
    {
      title: "Route Leg",
      description: "Template route leg",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis.getValue()}/TemplateBulkRouteLeg24072023.csv`,
      extension: "csv"
    },
    {
      title: "Route",
      description: "Template route",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/template-route-20210628.csv`,
      extension: "csv"
    },
    {
      title: "Transport Vendor",
      description: "Template transport vendor",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/transport1608520423.csv`,
      extension: "csv"
    },
    {
      title: "Vendor",
      description: "Template vendor",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/vendor1608520494.csv`,
      extension: "csv"
    },
    {
      title: "Mapping Client Location",
      description: "Template mapping client location",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/template-client-location-mapping-20210628.xlsx`,
      extension: "xlsx"
    },
    {
      title: "Genesis Commodity & Mapping to ECARGO Commodity",
      description: "Template Genesis Commodity & Mapping to ECARGO Commodity",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/template-commodity-20210628.csv`,
      extension: "csv"
    },
    {
      title: "Add new retail base rate",
      description: "Template Add new retail base rate",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis_announcement.getValue()}/add-new-retail-base-rate-2021-08-04.xlsx`,
      extension: "xlsx"
    },
    {
      title: "Bagging Group Location",
      description: "Template Bagging Group Location",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/template/template_bagging_grouping.csv`,
      extension: "csv"
    },
    {
      title: "SLA District",
      description: "Template SLA District",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/template/update_sla_district.csv`,
      extension: "csv"
    },
    {
      title: "Create SLA City",
      description: "Template Create SLA City",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/template/create_sla_city.csv`,
      extension: "csv"
    },
    {
      title: "Update SLA City",
      description: "Template Update SLA City",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/template/update_sla_city.csv`,
      extension: "csv"
    },
    {
      title: "Add Embargo",
      description: "Template Add Embargo",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis.getValue()}/add_embargo_1674101951.csv`,
      extension: "csv"
    },
    {
      title: "Update Embargo",
      description: "Template Update Embargo",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis.getValue()}/update_embargo_1674102029.csv`,
      extension: "csv"
    },
    {
      title: "Update Country",
      description: "Template Update Country",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis.getValue()}/update_country_1674102229.csv`,
      extension: "csv"
    },
    {
      title: "Add Country",
      description: "Template Add Country",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis.getValue()}/add_country_1674102317.csv`,
      extension: "csv"
    },
    {
      title: "Update City",
      description: "Template Update City",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis.getValue()}/update_city_1674102392.csv`,
      extension: "csv"
    },
    {
      title: "Add City",
      description: "Template Add City",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis.getValue()}/add_city_1674102441.csv`,
      extension: "csv"
    },
    {
      title: "Update District",
      description: "Template Update District",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis.getValue()}/update_district_1674102609.csv`,
      extension: "csv"
    },
    {
      title: "Add District",
      description: "Template Add District",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis.getValue()}/add_district_1686122909.csv`,
      extension: "csv"
    },
    {
      title: "Config Cargo",
      description: "Template Config Cargo",
      url: `https://prd-genesis.s3.ap-southeast-1.amazonaws.com/bulk/template/config_cargo_012025.csv`,
      extension: "csv"
    },
    {
      title: "AWB Reserve",
      description: "Template AWB Reserve",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis.getValue()}/Upload-Reserve-AWB-Number.csv`,
      extension: "csv"
    },
    {
      title: "Region",
      description: "Template Region",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis.getValue()}/add_region.csv`,
      extension: "csv"
    },
    {
      title: "Region City",
      description: "Template Region City",
      url: `${ConfigBucketStorage.config_bucket_dev_genesis.getValue()}/add_region_city.csv`,
      extension: "csv"
    },
    {
      title: "Kargo Grouping",
      description: "Template Kargo Grouping",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/bulk/template/rtc_city_group_1686634062.csv`,
      extension: "csv"
    },
    {
      title: "Create Promo Discount",
      description: "Template Create Promo Discount",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/bulk/template/Add%20Promo%20Discount.xlsx`,
      extension: "xlsx"
    },
    {
      title: "Update Promo Discount",
      description: "Template Update Promo Discount",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/bulk/template/update_promo_discount_20230808.xlsx`,
      extension: "xlsx"
    },
    {
      title: "Update Commodity",
      description: "Template Update Commodity",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/bulk/template/update-commodity_12-2024.csv`,
      extension: "csv"
    },
    {
      title: "Custom Status",
      description: "Update Custom Status HALCD & CNXCD",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/custom_status/update_custom_status_halcd_and_cnxcd.csv`,
      extension: "csv"
    },
    {
      title: "District Code Vendor Interpack",
      description: "Template District Code Vendor Interpack",
      url: `${ConfigBucketStorage.config_bucket_prd_genesis.getValue()}/bulk/template/district_vendor.csv`,
      extension: "csv"
    }
  ];

  get tempList() {
    if (this.accountType) {
      if (this.accountType === "internal") {
        return [...this.stepFillTemplate, ...this.internalTemplate];
      }
      return this.stepFillTemplate;
    }
    return [];
  }

  onDownload(url: string, filename: string, extension: string) {
    downloadFile(url, filename, extension);
  }
}
